<template>
    <div class="view-trip-list" v-if="translates && translates[langUrl]">
        <nav v-if="isDesktop && translates.navigation" class="navigation">
            <div class="link" @click="mainPage">{{ translates.navigation.button_route_main[lang] }} ></div>
            <div class="link" @click="myTrips">{{ translates.navigation.button_route_trips[lang] }}</div>
        </nav>
        <div style="display: flex; justify-content: space-between;">
            <h1 class="view-trip-list__title">{{ translates[langUrl].pageTitle[lang] }}</h1>
            <BaseButton @click="telegramUserLink">{{ translates[langUrl].button_botLink[lang] }}</BaseButton>
        </div>
        <div class="view-trip-list__content">
            <div v-if="isDesktop">
                <div class="view-trip-list__preview">
                    <template v-for="(trip, index) in trips">
                        <DesktopPreviewCard
                            :key="index"
                            :trip="trip"
                            :buttons="['edit', 'copy', 'moderated']"
                            @copy="copy"
                            @setStatus="setStatus" />
                    </template>
                </div>
            </div>
            <div v-else>
                <div class="view-trip-list__preview">
                    <template v-for="(trip, index) in trips">
                        <PreviewCard
                            :key="index"
                            :trip="trip"
                            :buttons="['edit', 'copy', 'moderated']"
                            @copy="copy"
                            @setStatus="setStatus" />
                    </template>
                </div>
            </div>
        </div>
        <v-pagination
            v-if="tripsCount > 10"
            v-model="page"
            :length="pageCount()"
            circle
            @input="getTrips"
        />
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';
    import PreviewCard from '../../components/common/trip/PreviewCard.vue';
    import BaseButton from '../../components/common/BaseButton.vue';
    import DesktopPreviewCard from '../../components/desktop/trip/PreviewCard.vue'
    import DesktopFilters from '../../components/desktop/trip/Filters.vue'
    import Filters from '../../components/mobile/trip/Filters.vue'
    import { TRIP_STATUS } from '@/vars';

    export default {
        name: 'Trips',
        metaInfo: {
            title: 'Мои туры'
        },
        components: {
            PreviewCard,
            DesktopPreviewCard,
            DesktopFilters,
            Filters,
            BaseButton
        },
        data: () => ({
            isDesktop: false,
            filter: {},
            page: 1,
            langUrl: '/user/trips'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('trips', {
                trips: state => state.entities,
                tripsCount: state => state.entitiesCount,
            }),
            ...mapState('auth', {
                user: state => state.user
            }),
            ...mapState('translates', {
                translates: state => state.entities
            })
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            await store.dispatch('auth/fetch');
            store.commit('trips/CLEAR_ENTITIES');
            await this.getTrips();
        },
        // async updated() {
        //     await this.getTrips();
        // },
        methods: {
            pageCount() {
                return Math.ceil(this.tripsCount / 10)
            },
            async getTrips() {
                await store.dispatch('trips/fetch', { 
                    filter: {
                        ...{ user: this.user._id } 
                    }, 
                    page: this.page 
                });
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async myTrips () {
                await this.$router.push({ name: 'user-trips' });
            },
            async mainPage () {
                await this.$router.push({ name: 'home' });
            },
            async applyFilters({ filter, sorting }) {
                this.filter = filter;
                this.sorting = sorting;
                await this.getTrips();
            },
            async copy({ id }) {
                await store.dispatch('trips/copy', { id });
                await this.getTrips();
            },
            async setStatus({ id, status }) {
                await store.dispatch('trips/setStatus', { id, status });
                await this.getTrips();
            },
            telegramUserLink() {
                window.open(`https://t.me/${process.env.VUE_APP_TELEGRAM_USER_BOT}`, '_blank');
            },
        }
    }
</script>

<style lang="scss">
.view-trip-list {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    @media all and (min-width: 768px) {
        padding: 0 32px;
    }
    @media all and (min-width:1464px) {
        padding: 0;
    }
    &__menu{
        margin: 13px 24px 0;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #44414D;
        @media all and (min-width:768px) {
            margin: 13px 0 0;
        }
    }
    &__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #3A4256;
        margin: 32px 24px 12px;
        @media all and (min-width:768px) {
        font-weight: bold;
        font-size: 38px;
        line-height: 46px;
        color: #273155;
        margin: 40px 0 36px;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
    }
    &__preview {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @media all and (min-width:768px) {
            flex-direction: column;
            justify-content: space-between;
            // padding: 0 32px;
        }
    }
}

</style>
